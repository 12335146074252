// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".agreementLink_Ok8TD {\n  margin-bottom: 24px;\n}\n.agreementLink_Ok8TD span {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEmission/DFAEmissionCardPage/components/Footer/Modals/AgreementLink/agreementLink.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACR","sourcesContent":[".agreementLink {\n    margin-bottom: 24px;\n\n    & span {\n        font-family: 'SF Pro Display';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 12px;\n        line-height: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreementLink": "agreementLink_Ok8TD"
};
export default ___CSS_LOADER_EXPORT___;
