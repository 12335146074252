import { CertificateType } from '@dltru/dfa-models'
import {
    Box,
    Button,
    ConditionsModal,
    Divider,
    Modal,
    Price,
    LabelRow as Row,
    UserOutlined,
} from '@dltru/dfa-ui'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { repaymentAgreementText } from '@pages/DFAEmission/DFAEmissionCardPage/resources'

import style from './confirmRepaymentModal.m.less'
import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

interface IConfirmModal {
    isModalVisible: boolean
    onOk: (skid: string) => void
    onSignOk: (skid: string) => void
    onCancel: () => void
    dfaId?: string
}

export const ConfirmRepaymentModal: FC<IConfirmModal> = ({ isModalVisible, onOk, onCancel, onSignOk }) => {
    const userFullName = useSelector(profileSelector.userFullName)
    const fullBalance = useSelector(dfaDetailsSelector.selectFullBalance)
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isConditionModalVisible, setIsConditionModalVisible] = useState(false)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const pricePerDfa = useSelector(dfaDetailsSelector.selectRedeemPricePerDfa)

    const onSubscribe = (cert: CertificateType) => {
        onOk(cert.skid)
    }

    const onSign = (cert: CertificateType) => {
        onSignOk(cert.skid)
    }
    const price = fullBalance * (pricePerDfa ?? 0)

    return (
        <>
            <Modal
                visible={isModalVisible}
                width={522}
                title="Согласие на погашение ЦФА"
                onCancel={onCancel}
            >
                <div className={style.confirmRepaymentModal}>
                    <Box direction="column" margin={[0, 0, 24, 0]}>
                        <div className={style.userInfo}>
                            <span className={style.userInfoTitle}>Сумма, погашенная владельцу</span>
                            <div className={style.userInfoRow}>
                                <div className={style.userInfoName}>
                                    <UserOutlined />
                                    <span className={style.userName}>{userFullName}</span>
                                </div>
                                <Price price={price} />
                            </div>
                        </div>
                        <Row label="Тикер">
                            <strong>{dfa.ticker_symbol}</strong>
                        </Row>
                        <Divider />
                        <p className="modal-content-text">
                            Вы являетесь владельцем данного ЦФА. Эмитент в рамках погашения провел
                            прямой расчет с владельцами ЦФА.
                        </p>
                        <p className="modal-content-text">
                            Если вы получили выплату, дайте свое согласие на погашение выпуска на
                            Платформе ОИС.
                        </p>
                        <p className="modal-content-text">
                            Если вы не получили выплату, свяжитесь с эмитентом.
                        </p>
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 8, 16, 8]}
                        direction="row"
                        justify="right"
                    >
                        <Button borderRadius={12} onClick={onCancel}>
                            Отмена
                        </Button>

                        <Button
                            borderRadius={12}
                            type="primary"
                            onClick={() => setIsVisibleCertificateModal(true)}
                        >
                            Да, я даю согласие
                        </Button>
                    </Box>
                    <div className="modal-footer-gray-text">
                        Соглашаясь на погашение, вы принимаете{' '}
                        <Button type="linkText" onClick={() => setIsConditionModalVisible(true)}>
                            условия
                        </Button>
                        .
                    </div>
                </div>
            </Modal>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
                onSign={onSign}
            />
            <ConditionsModal
                isModalVisible={isConditionModalVisible}
                setIsModalVisible={setIsConditionModalVisible}
                title="Условия"
                text={repaymentAgreementText}
            />
        </>
    )
}
