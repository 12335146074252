import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { DocumentsEnum, beforeEmitmentStatuses } from '@dltru/dfa-models'
import { CONTENT_FOOTER_ID, ContentFooterBox, Portal } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { DownloadBtn } from '@components/DownloadBtn/DownloadBtn'

import { ApplyTradeAction } from './Actions/ApplyTradeAction'
import { BankAccountDetailsAction } from './Actions/BankAccountDetails/BankAccountDetailsAction'
import { BuyDfaAction } from './Actions/BuyDfaAction'
import { ConfirmAction } from './Actions/ConfirmAction/ConfirmAction'
import { ConfirmRepaymentAction } from './Actions/ConfirmRepayment'
import { CreateEncumbranceAction } from './Actions/CreateEncumbranceAction'
import { RejectEmissionAction } from './Actions/RejectEmissionAction'
import { RepaymentAction } from './Actions/RepaymentAction'
import { SellDfaAction } from './Actions/SellDfaAction'
import { SendForApproveAction } from './Actions/SendForApproveAction'
import { SetEmitmentAction } from './Actions/SetEmitmentAction'
import { SetEmitmentAsInvalidAction } from './Actions/SetEmitmentAsInvalidAction'

export const Footer: FC = () => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const isPartiallyBlocked = useSelector(profileSelector.selectIsUserBlocked)
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const currentUser = useSelector(authSelector.selectUserUid)
    const isQualify = useSelector(profileSelector.selectIsQualify)

    const isEmitter = currentUser === dfaDetails.emitter_id

    const showDownLoadButton = isEmitter && beforeEmitmentStatuses.includes(dfaDetails.status)

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    <ContentFooterBox
                        left={
                            showDownLoadButton ? (
                                <DownloadBtn
                                    documentType={DocumentsEnum.dfa_release_result}
                                    params={{
                                        dfa_id: dfaDetails.id,
                                        ticker: dfaDetails?.ticker_symbol,
                                    }}
                                    title="Отчёт об итогах выпуска ЦФА"
                                />
                            ) : undefined
                        }
                    >
                        <BankAccountDetailsAction />
                        <CreateEncumbranceAction
                            balance={dfaDetails?.balance}
                            isPartiallyBlocked={isPartiallyBlocked}
                        />
                        {/*<OwnershipTransferRequestAction
                            status={dfaDetails.status}
                            isPartiallyBlocked={isPartiallyBlocked}
                            dfa={dfaDetails}
                        />*/}
                        <RejectEmissionAction />
                        <SendForApproveAction />
                        <ApplyTradeAction
                            isPartiallyBlocked={isPartiallyBlocked}
                            isQualify={isQualify}
                        />
                        <SetEmitmentAsInvalidAction
                            status={dfaDetails.status}
                            isEmitter={isEmitter}
                            dfa={dfaDetails}
                        />
                        <SetEmitmentAction
                            isEmitter={isEmitter}
                            isPartiallyBlocked={isPartiallyBlocked}
                            dfa={dfaDetails}
                        />
                        <BuyDfaAction dfa={dfaDetails} isPartiallyBlocked={isPartiallyBlocked} />
                        <SellDfaAction dfa={dfaDetails} isPartiallyBlocked={isPartiallyBlocked} />
                        <ConfirmRepaymentAction dfa={dfaDetails} />
                        <RepaymentAction isPartiallyBlocked={isPartiallyBlocked} />
                        <ConfirmAction dfa={dfaDetails} />
                    </ContentFooterBox>
                </Portal>
            )}
        </>
    )
}
