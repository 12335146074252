import { Button, ConditionsModal } from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import style from './agreementLink.m.less'

export const AgreementLink: FC<{ text?: React.ReactNode; styles?: React.CSSProperties }> = ({
    text = 'Соглашение',
    styles = { textAlign: 'center' },
}) => {
    const [isConditionModalVisible, setIsConditionModalVisible] = useState(false)

    const openConditionModal = (e: React.SyntheticEvent) => {
        e.preventDefault()
        setIsConditionModalVisible(true)
    }

    return (
        <div className={style.agreementLink}>
            <span style={styles}>
                Инициируя процедуру погашения ЦФА, вы соглашаетесь с{' '}
                <Button type="linkText" onClick={openConditionModal}>
                    условиями
                </Button>
            </span>
            .
            <ConditionsModal
                isModalVisible={isConditionModalVisible}
                setIsModalVisible={setIsConditionModalVisible}
                title="Условия"
                text={text}
            />
        </div>
    )
}
